<template>
  <div class="equipments-page" v-if="currentAccount">
    <h1>Equipments</h1>
    <hr />

    <div class="d-flex align-items-center align-content-center mb-2">
      <button
        v-if="currentAccount.hasPermission('equipments.create')"
        class="equipment-create btn btn-primary float-right"
        @click="createEquipment"
      >
        Create new
      </button>

      <div class="ms-3 form-check">
        <label for="deletedOnly" class="form-check-label">Archived only</label>
        <input type="checkbox" class="form-check-input" id="deletedOnly" v-model="deletedOnly" />
      </div>
    </div>

    <div>
      <input type="text" class="form-control search" placeholder="Search" v-model="search" @input="onSearch" />
      <ag-grid-vue
        class="ag-theme-material"
        style="height: 75vh"
        rowModelType="serverSide"
        :gridOptions="gridOptions"
        :getRowId="getRowId"
        @grid-ready="onGridReady"
      >
      </ag-grid-vue>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue3';
import { mapGetters } from 'vuex';
import { getEquipmentList, deleteEquipment, restoreEquipment } from '@/api';
import ActionsCellRenderer from '@/components/ActionsCellRenderer';
import { debounce } from '@/utils';

export default {
  name: 'EquipmentsPage',
  components: { AgGridVue, ActionsCellRenderer },
  computed: {
    ...mapGetters(['account/current']),
    currentAccount() {
      return this['account/current'];
    },
  },
  data: () => ({
    gridApi: null,
    getRowId: params => `${params.data.id}`,
    gridOptions: {
      suppressContextMenu: true,
      suppressCellFocus: true,
      cacheBlockSize: 30,
      maxBlocksInCache: 5,
      rowBuffer: 0,
    },
    defaultColDef: {
      resizable: true,
      suppressMenu: true,
      suppressMovable: true,
      floatingFilterComponentParams: {
        suppressFilterButton: true,
      },
    },
    search: '',
    deletedOnly: false,
  }),
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;

      this.gridOptions.context = this;

      this.gridApi.setDefaultColDef(this.defaultColDef);
      this.gridApi.setColumnDefs([
        {
          field: 'name',
        },
        {
          field: 'actions',
          resizable: false,
          cellRenderer: 'ActionsCellRenderer',
          cellRendererParams: {
            actions: [
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('equipments.edit'),
                title: 'Edit',
                icon: 'fa-edit',
                onClick: id => this.editEquipment(id),
              },
              {
                show: entity => !entity.deletedAt && this.currentAccount.hasPermission('equipments.remove'),
                title: 'Delete',
                icon: 'fa-trash-alt',
                onClick: id => this.deleteEquipment(id),
              },
              {
                show: entity => !!entity.deletedAt && this.currentAccount.hasPermission('vehicles.remove'),
                title: 'Restore',
                icon: 'fa-trash-restore-alt',
                onClick: id => this.restoreEquipment(id),
              },
            ],
          },
        },
      ]);

      this.resizeColumns();

      this.gridApi.setServerSideDatasource({
        parent: this,
        async getRows(params) {
          try {
            const startRowIndex = params.request.startRow;
            const endRowIndex = params.request.endRow;
            const expectedRowCount = endRowIndex - startRowIndex;
            const currentRowCount = params.api.getServerSideStoreState()[0]?.rowCount || 0;

            const response = await getEquipmentList(
              expectedRowCount,
              params.request.startRow,
              params.context.search || undefined,
              params.context.deletedOnly,
            );

            if (response.errors) {
              throw new Error(response.errors[0].message);
            }

            const equipments = response.data.equipments;
            const receivedCount = equipments.length;

            let rowCount = 0;
            if (endRowIndex <= currentRowCount) {
              rowCount = currentRowCount;
            } else {
              rowCount = receivedCount < expectedRowCount ? startRowIndex + receivedCount : endRowIndex + 1;
            }

            params.success({
              rowData: equipments,
              rowCount,
            });
          } catch (err) {
            console.error(err);
            params.fail();
          }
        },
      });

      this.storeSubscription = this.$store.subscribe((mutation, state) => {
        switch (mutation.type) {
          case 'equipment/ADD_TO_LIST':
            this.gridApi.applyServerSideTransaction({
              add: [mutation.payload],
            });
            break;

          case 'equipment/UPDATE_IN_LIST':
            this.gridApi.applyServerSideTransaction({
              update: [mutation.payload],
            });
            break;

          case 'equipment/REMOVE_FROM_LIST':
            this.gridApi.applyServerSideTransaction({
              remove: [mutation.payload],
            });
            break;

          default:
            break;
        }
      });
    },

    resizeColumns() {
      this.gridApi.sizeColumnsToFit({
        columnLimits: [{ key: 'actions', maxWidth: 120 }],
      });
    },

    createEquipment() {
      this.$router.push({ name: 'equipment-create' });
    },

    editEquipment(id) {
      this.$router.push({ name: 'equipment-edit', params: { id } });
    },

    async deleteEquipment(id) {
      try {
        const response = await deleteEquipment(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.$toast.success('Equipment was deleted.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    async restoreEquipment(id) {
      try {
        const response = await restoreEquipment(id);
        if (response.errors) {
          throw new Error(response.errors[0].message);
        }

        this.gridApi.applyServerSideTransaction({ remove: [{ id }] });
        this.$toast.success('Equipment was restored.', { duration: 2000 });
      } catch (err) {
        this.$toast.error(err.message);
      }
    },

    onSearch() {
      this.gridApi.refreshServerSide({ purge: true });
    },
  },
  mounted() {
    this.onSearch = debounce(this.onSearch.bind(this), 500);

    this.resizeColumns = this.resizeColumns.bind(this);
    window.addEventListener('resize', this.resizeColumns);
  },
  unmounted() {
    this.storeSubscription?.();
    window.removeEventListener('resize', this.resizeColumns);
  },
  watch: {
    deletedOnly: {
      handler(value) {
        this.gridApi.refreshServerSide({ purge: true });
      },
    },
  },
};
</script>

<style scoped>
.equipments-page {
  width: 100%;
  padding: 1rem;
}

.equipment-create {
  margin: 0.2em;
}

.equipments-page .search {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  line-height: 2.2;
}
</style>
